<script lang="ts">
		import '$lib/styles/styles.scss';
		import Header from '../lib/components/shared/Header.svelte';
		import Footer from '$lib/components/shared/Footer.svelte';
		import MobileHeader from '$lib/components/shared/MobileHeader.svelte';
		import MobileFooter from '$lib/components/shared/MobileFooter.svelte';

		import { Modals, closeModal } from 'svelte-modals';
		import { onMount } from 'svelte';
		import { recaptchaKey } from '$lib/config/system';
		const recaptchaUrl = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;

		onMount(() => {
				const referrer = document.referrer;
				const hostname = window.location.origin;
				const savedKey = sessionStorage.getItem('referrer');

				if (!referrer.startsWith(hostname) && !savedKey) {
						sessionStorage.setItem('referrer', referrer);
				}

				const sessionLandingPage = sessionStorage.getItem('landing');
				if (!sessionLandingPage) {
						sessionStorage.setItem('landing', window.location.href);
				}
		});
</script>

<svelte:head>
	<script src="{recaptchaUrl}" async defer></script>
</svelte:head>

<Modals>
	<div slot="backdrop" class="backdrop" on:click="{closeModal}" on:keydown="{closeModal}"></div>
</Modals>

<div class="app flex-column">
	<div class="desktop-header">
		<Header />
	</div>

	<div class="mobile-header">
		<MobileHeader />
	</div>

	<main class="flex-column">
		<slot />
	</main>

	<div class="desktop-footer">
		<Footer />
	</div>

	<div class="mobile-footer">
		<MobileFooter />
	</div>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	:global(.grecaptcha-badge) {
		visibility: hidden;
	}

	.backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: #f6f6f6;
		opacity: 0.2;
		filter: blur(4px);
		widows: 100vw;
		height: 100vh;
		z-index: 100;
	}

	.app {
		min-height: 100vh;

		main {
			box-sizing: border-box;
		}

		.mobile-header,
		.mobile-footer {
			display: none;
		}

		.desktop-header,
		.desktop-footer {
			display: block;
		}
	}

	@media (max-width: $tablet-size) {
		.app {
			.desktop-header,
			.desktop-footer {
				display: none;
			}

			.mobile-header,
			.mobile-footer {
				display: block;
			}
		}
	}
</style>

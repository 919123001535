<script lang="ts">
	import type { MenuLink } from '$lib/domain/menu-link.interface';
	import { createEventDispatcher } from 'svelte';
	import { onMount } from 'svelte';
	import ArrowGreenRight from "$icons/arrow-green-right.svelte";

	const dispatch = createEventDispatcher();

	export let menuPosition: string;
	export let arrowPosition: string;
	export let visible: boolean;
	export let subMenus: MenuLink[] = [];
	
	let hoveredIndex: number | null = null;
	const closeMenu = () => dispatch('closeMenu');
	
	onMount(() => {
			if (typeof IntersectionObserver === 'undefined') {
					return;
			}

			let observer = new IntersectionObserver(
					(entries, observer) => {
							entries.forEach((entry) => {
									if (!entry.isIntersecting) {
											return;
									}

									const htmlTarget = entry.target as HTMLImageElement;
									htmlTarget.src = htmlTarget.dataset.src || '';
									htmlTarget.classList.remove('lazyload');
									observer.unobserve(htmlTarget);
							});
					},
					{ threshold: 0, rootMargin: '20px' }
			);

			document.querySelectorAll(`.lazyload`).forEach((entry) => observer.observe(entry));
	});

	$: hidden = !visible;
	$: menuLeft = menuPosition ? menuPosition : null;
	$: arrow = arrowPosition ? arrowPosition : null;
</script>

<div class="menu flex-row"
		 class:hidden="{hidden}"
		 style="left: {menuLeft}px">
	<div class="menu-arrow" style="left: {arrow}px"></div>
	<div class="menu-wrapper flex-row">
		<ul class="menu-wrapper-column">
			{#each subMenus as menu, index}
				<li on:mouseenter={() => (hoveredIndex = index)}
						on:mouseleave={() => (hoveredIndex = null)}>
					<a on:click="{() => closeMenu()}"
						 class="header-button {menu.submenu ? 'has-sub': ''} {menu.submenu && hoveredIndex === index ? 'sub-active' : ''}"
						 href="{menu.url}">
						{menu.text}
						{#if menu.submenu}
							<ArrowGreenRight/>
						{/if}
					</a>
					{#if (menu.submenu)}
						<div class="sub-submenu menu-wrapper"
								 class:visible="{hoveredIndex === index}">
							<span class="sub-subtitle">Niche expertise</span>
							<ul class="{menu.text === 'Technology' ? 'menu-wrapper-row' : ''}">
								{#each menu.submenu as subMenu}
									<li>
										<a on:click="{() => closeMenu()}" class="header-button" href="{subMenu.url}">
											{subMenu.text}
										</a>
									</li>
								{/each}
							</ul>
						</div>
					{/if}
				</li>
			{/each}
		</ul>
	</div>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	.hidden {
		display: none !important;
		opacity: 0;
	}

	.menu {
		position: absolute;
		top: 98px;
		min-width: 268px;
		max-width: 315px;
		color: $main-black;
		background-color: $light-grey;
		border-radius: 12px;
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;

		.menu-arrow {
			position: absolute;
			transform: translateX(-50%);
			top: -20px;
			width: 0;
			height: 0;
			border-left: 24px solid transparent;
			border-right: 24px solid transparent;
			border-bottom: 24px solid $light-grey;
			z-index: 0;
		}

		.menu-wrapper {
			width: 100%;
			justify-content: start;
			flex-direction: column;
			padding: 24px 0;

			&-column , &-row {
				padding: 0;
				margin: 0;
				list-style: none;

				.header-button {
					color: $main-black;
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;

					&:hover {
						color: $green-accent;
					}
				}
				
				.header-button.has-sub {
		  		&:hover {
						background-color: $light-green;
					}
				}
				
				.sub-active {
		 			background-color: $light-green;
				}
			}

			&-column {
				li {
					a {
					  box-sizing: border-box;
					  display: flex;
						justify-content: space-between;
						align-items: center;
					  width: 100%;
					  padding: 12px 24px;
					}
				}
				.sub-submenu {
					visibility: hidden;
				}
				.sub-submenu.visible {
					visibility: visible;
				}
			}

			&-row {
				display: flex;
				flex-direction: row;
				width: fit-content;
				flex-wrap: wrap;
	 	 }

			.sub-menu-title {
				.header-button {
					color: $main-black;
					
					&:hover {
						color: $green-accent;
					}
				}
				
				a {
					box-sizing: border-box;
					display: inline-block;
					width: 100% ;
					padding-left: 24px;
					padding-right: 24px;
				}
			}
			
			.sub-submenu {
				margin-left: -30px;
				position: absolute;
				left: 100%;
				top: 0;
				bottom: 0;
				min-width: 250px;
				max-width: 300px;
				background-color: $light-grey;
				backdrop-filter: blur(40px);
				border-radius: 12px;
				box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				z-index: -1;
				
				ul {
					list-style: none;
					padding-left: 30px;
				}
				
				.sub-subtitle {
					display: block;
					padding: 12px 24px 12px 54px ;
					color: $dark-grey;
					font-size: 12px;
					font-weight: 700;
					line-height: 22px;
				}
			}
		}
	}
</style>
